import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const ImgWrapper = ({ image, ...other }) => {
  if (!image) return null;
  const { childImageSharp, publicURL } = image;
  if (childImageSharp) {
    return <GatsbyImage image={getImage(image)} {...other} />;
  }
  const { alt, ...rest } = other;
  const imgUrl = publicURL ? publicURL : image;
  return <img src={imgUrl} alt={alt} {...rest} />;
};

export default ImgWrapper;
