import React from 'react';
import Img from './img-wrapper';
import Evento from './evento';
import ArticlePage from './article-page';
import Publication from './publication';
import Breadcrumb from './breadcrumb';

const TemplateEventi = ({
  title,
  titleEvents,
  titlePublications,
  events,
  publications,
  titlePage,
  textPage,
  imagePage,
  articoliPage
}) => {
  const breadcrumb = [
    {
      name: title
    }
  ];
  return (
    <>
      <div className="section section--dark mt-0">
        <div className="container-xl">
          <div className="row mb-3">
            <div className="col-12">
              <Breadcrumb breadcrumb={breadcrumb} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h1 className="my-3">{title}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container-xl">
          <div className="row">
            <div className="col">
              <h2>{titleEvents}</h2>
            </div>
          </div>
          {events ? events.map((data, i) => <Evento {...data} key={i} />) : null}
        </div>
      </div>
      <div className="section section--dark">
        <div className="container-xl">
          <div className="row">
            <div className="col mt-2 mb-3">
              <h2>{titlePublications}</h2>
            </div>
          </div>
          {publications ? publications.map((item, i) => <Publication {...item} key={i} />) : null}
        </div>
      </div>
      <div className="section">
        <span id="articoli-page" className="section__anchor"></span>
        <div className="container-xl">
          <div className="row">
            <div className="col-12">
              <h2>{titlePage}</h2>
            </div>
          </div>
          <div className="row mb-5 mb-md-4">
            <div className="col-md-5 order-md-2 mb-3 mb-md-0">
              {imagePage ? (
                <Img image={imagePage} style={{ maxWidth: '100%' }} alt="Team PAGE" />
              ) : null}
            </div>
            <div className="col-md-7">
              <div dangerouslySetInnerHTML={{ __html: textPage }}></div>
            </div>
          </div>
          {articoliPage ? (
            <>
              <h2>Articoli di PAGE</h2>
              {articoliPage.map((data, i) => (
                <ArticlePage {...data} key={i} i={i} />
              ))}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default TemplateEventi;
