import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import Img from './img-wrapper';

const ArticlePage = ({ title, date, image, abstract, link, pdf, i = 0 }) => {
  const formattedDate = useMemo(
    () => (date ? dayjs(date).locale('it').format('DD MMM YYYY') : date),
    [date]
  );

  return (
    <div className="row mb-5">
      <div className="col-12 d-md-none">
        <h3>{title}</h3>
      </div>
      <div className={`col-12 col-md-5${i % 2 === 0 ? '' : ' order-md-2'}`}>
        {date ? <h4>{formattedDate}</h4> : null}
        {image ? <Img image={image} title={title} alt={title} /> : null}
        <div className="row mt-3">
          <div className="col-sm-7 col-md-12 col-lg-7">
            <a href={link} title={title} target="_blank" rel="noopener noreferrer">
              Leggi l'articolo su PAGE
            </a>
          </div>
          {pdf ? (
            <div className="col-sm-5 mt-2 mt-sm-0 col-md-12 mt-md-2 col-lg-5 mt-lg-0">
              <a
                href={pdf.publicURL || pdf}
                title={title}
                target="_blank"
                rel="noopener noreferrer"
              >
                Visualizza il pdf
              </a>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-12 col-md-7 mt-3 mt-md-0">
        <div className="d-none d-md-block">
          <h3>{title}</h3>
        </div>
        <div dangerouslySetInnerHTML={{ __html: abstract }}></div>
      </div>
    </div>
  );
};

export default ArticlePage;
