import React from 'react';
import Img from './img-wrapper';

const Evento = ({ title, subtitle, image, description }) => {
  return (
    <div className="row mb-5">
      <div className="col-12 d-md-none">
        <h3>{title}</h3>
        {subtitle && <h4>{subtitle}</h4>}
      </div>
      <div className="col-12 col-md-6">
        <Img image={image} title={title} alt={title} />
      </div>
      <div className="col-12 col-md-6 mt-3 mt-md-0">
        <div className="d-none d-md-block">
          <h3>{title}</h3>
          {subtitle && <h4>{subtitle}</h4>}
        </div>
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
      </div>
    </div>
  );
};

export default Evento;
