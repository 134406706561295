import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import TemplateEventi from '../components/template-eventi-pubblicazioni';

const EventiPage = ({ data }) => {
  const { page, articoliPage } = data;
  return (
    <Layout data={data}>
      <Seo title={page.tagTitle || page.title} description={page.metaDescription} data={data} />
      <TemplateEventi {...page} articoliPage={articoliPage.edges.map((edge) => edge.node)} />
    </Layout>
  );
};

export default EventiPage;
export const query = graphql`
  query EventiPubblicazioniQuery {
    ...HeaderFragment
    ...SeoFragment
    page: eventiPubblicazioniPageJson {
      title
      tagTitle
      metaDescription
      titleEvents
      titlePublications
      events {
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(width: 500, layout: CONSTRAINED, placeholder: TRACED_SVG)
          }
        }
        description
      }
      publications {
        title
        abstract
        placeDate
        pdf {
          publicURL
        }
        link
      }
      titlePage
      textPage
      imagePage {
        childImageSharp {
          gatsbyImageData(width: 450, layout: CONSTRAINED, quality: 100, placeholder: TRACED_SVG)
        }
      }
    }
    articoliPage: allPageArticlesJson(sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          date
          image: image {
            childImageSharp {
              gatsbyImageData(
                width: 500
                layout: CONSTRAINED
                quality: 100
                placeholder: TRACED_SVG
              )
            }
          }
          abstract
          pdf {
            publicURL
          }
          link
        }
      }
    }
  }
`;
