import React from 'react';
import SvgIcon from './svg-icon';
// import Pdf from '../images/svg-icons/file-earmark-pdf.svg';

const Publication = ({ title, abstract, placeDate, pdf, link }) => {
  return (
    <div className="mt-3 pb-3">
      <div className="row">
        <div className="col-12">
          <h3>{title}</h3>
          {placeDate ? <p>{placeDate}</p> : null}
        </div>
      </div>
      {abstract && (
        <div className="row">
          <div className="col-12">
            <div dangerouslySetInnerHTML={{ __html: abstract }}></div>
          </div>
        </div>
      )}
      <div className="row">
        {pdf ? (
          <div className="col-sm-6">
            <div className="d-flex">
              <a
                href={pdf.publicURL || pdf}
                className="download-link me-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <Pdf /> */}
                <SvgIcon icon="file-earmark-pdf" />
                Vedi il pdf
              </a>
            </div>
          </div>
        ) : null}
        {link ? (
          <div className="col-sm-6">
            <a href={link} title={title}>
              Leggi l'articolo
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Publication;
